import React, { useEffect, useState } from "react";
import { fetchAppConfiguration, isStateLogin, setConfig, showFieldInColdef } from '../src/utilities/Hsp'
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import "./App.css";
import "./styles/Nrjwtr.css";
import "tailwindcss/tailwind.css";
import { NrjRequire } from "./utilities/NrjRequire";
import { SetApp } from "./utilities/NrjUtility";
import Hlp_mstr from "./app/admin/hlp_mstr";
import Hlp_grid from "./app/admin/hlp_grid";
import { Tooltip } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import LoadingComponent from "./components/LoadingComponent";
import { GetResponseWnds, getCmpId, getUsrId } from "./utilities/utilities";
import DailySummaryByScnby from "./app/AnnualRpt/DailySummaryByScnby";
import DailySummaryByScnby_1 from "./app/AnnualRpt/DailySummaryByScnby_1";
import { Hdr_1 } from "./app/Hdr_1";
import { CSSProperties } from "react";
import { useIdleTimer } from 'react-idle-timer'

// Define styles for the loader content rsuit loader 
const loaderContainerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center', 
  alignItems: 'flex-start',  
  height: '100vh',         
  width: '100vw',
  paddingTop: '20px',    
};

// Define styles for the loader content
const loaderStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column', // Explicitly type flexDirection as a valid string
  alignItems: 'center',
};

const LzAnnualHospitalReport = React.lazy(() => import("./app/AnnualRpt/AnnualHospitalReport"));
const LzAnnualHospitalReport_1 = React.lazy(() => import("./app/AnnualRpt/AnnualHospitalReport_1"));
const LzDailyDetailedScnby = React.lazy(() => import("./app/AnnualRpt/DailyDetailedScnby"))
const LzDailyDetailedScnby_1 = React.lazy(() => import("./app/AnnualRpt/DailyDetailedScnby_1"))
const LzMonthlySummaryReport = React.lazy(() => import("./app/Reports/MonthlySummary"))
const LzMonthlySummaryReport_1 = React.lazy(() => import("./app/Reports/MonthlySummary_1"))
const LzDailyReportsFactory = React.lazy(() => import('./app/Reports/DailyReportsFactory'))
const LzDailyReportsFactory_1 = React.lazy(() => import('./app/Reports/DailyReportsFactory_1'))
const LzDuplicateSpcb = React.lazy(() => import('./app/Reports/DuplicateSpcb'))
const LzDuplicateSpcb_1 = React.lazy(() => import('./app/Reports/DuplicateSpcb_1'))
const LzWrongGeoLocation = React.lazy(() => import('./app/hcf/WrongGeoLocation'))
const LzWrongGeoLocation_1 = React.lazy(() => import('./app/hcf/WrongGeoLocation_1'))
const LzVehicleHcfMap = React.lazy(() => import('./app/Reports/VehicleHcfMap'))
const LzVehicleHcfMap_1 = React.lazy(() => import('./app/Reports/VehicleHcfMap_1'))
const LzTestingWasteBag = React.lazy(() => import('./app/Reports/TestingWasteBag'))
const LzTestingWasteBag_1 = React.lazy(() => import('./app/Reports/TestingWasteBag_1'))
const LzGoogleRoute = React.lazy(() => import("./app/Reports/GoogleRoute"))
const LzGoogleRoute_1 = React.lazy(() => import("./app/Reports/GoogleRoute_1"))
const LzGoogleRouteUsers = React.lazy(() => import("./app/Reports/GoogleRouteUsers"))
const LzGoogleRouteUsers_1 = React.lazy(() => import("./app/Reports/GoogleRouteUsers_1"))
const LzBhuvanRoute = React.lazy(() => import("./app/Reports/BhuvanRoute"))
const LzWstbgDisplay = React.lazy(() => import("./app/Reports/WstbgDisplay"))
const LzCbwtfDisplay = React.lazy(() => import("./app/Reports/CbwtfDisplay"))
const LzHcfCount = React.lazy(() => import("./app/Reports/HcfCount"))
const LzAggregator_1 = React.lazy(() => import("./app/brds/Aggregator_1"))
const LzUnsentData = React.lazy(() => import("./app/Reports/UnsentData"))
const LzUnsentData_1 = React.lazy(() => import("./app/Reports/UnsentData_1"))
const LzNrjForgotPassword = React.lazy(() => import("./login/NrjForgotPassword"))
const LzNrjForgotPassword_1 = React.lazy(() => import("./login/NrjForgotPassword_1"))
const LzDataCorrection = React.lazy(() => import("./components/reusable/DataCorrection"))
const LzMonthlyReportAllHcf_1 = React.lazy(() => import("./app/Reports/MonthlyReportAllHcf_1"))
const LzCbwtfReportDisplay = React.lazy(() => import("./app/Reports/CbwtfReportDisplay"))
const LzCbwtfReportDisplay_1 = React.lazy(() => import("./app/Reports/CbwtfReportDisplay_1"))
const LzGoogleMap = React.lazy(() => import("./app/Reports/GoogleMap"))
const LzGoogleMapLive = React.lazy(() => import("./app/Reports/GoogleMapLive"))
const LzGoogleMapLive_1 = React.lazy(() => import("./app/Reports/GoogleMapLive_1"))
const LzAnnlWstStrg = React.lazy(() => import("./app/AnnualRpt/AnnlWstStrg"));
const LzAnnlWstStrg_1 = React.lazy(() => import("./app/AnnualRpt/AnnlWstStrg_1"));
const LzAnnlEqp = React.lazy(() => import("./app/AnnualRpt/AnnlEqp"));
const LzAnnlEqp_1 = React.lazy(() => import("./app/AnnualRpt/AnnlEqp_1"));
const LzAnnlWstWt = React.lazy(() => import("./app/AnnualRpt/AnnlWstWt"));
const LzAnnlWstWt_1 = React.lazy(() => import("./app/AnnualRpt/AnnlWstWt_1"));
const LzAnnlRpt = React.lazy(() => import("./app/AnnualRpt/AnnlRpt"));
const LzAnnlRpt_1 = React.lazy(() => import("./app/AnnualRpt/AnnlRpt_1"));
const LzAnnlMisc = React.lazy(() => import("./app/AnnualRpt/AnnlMisc"));
const LzAnnlMisc_1 = React.lazy(() => import("./app/AnnualRpt/AnnlMisc_1"));
const LzBarCode = React.lazy(() => import("./app/hcf/BarocdeLablelInformation"))
const LzBarCode_1 = React.lazy(() => import("./app/hcf/BarocdeLablelInformation_1"))
const LzHCFDisplay = React.lazy(() => import("./app/Reports/HcfDisplay"));
const LzHCFDisplay_1 = React.lazy(() => import("./app/Reports/HcfDisplay_1"));
const LzProperWstbg = React.lazy(() => import("./app/Reports/ProperWstbg"));
const LzProperWstbg_1 = React.lazy(() => import("./app/Reports/ProperWstbg_1"));
const LzImProperWstbg = React.lazy(() => import("./app/Reports/ImProperWstbg"));
const LzImProperWstbg_1 = React.lazy(() => import("./app/Reports/ImProperWstbg_1"));
const LzAddRoute = React.lazy(() => import("./app/brds/AddRoute"));
const LzAddRoute_1 = React.lazy(() => import("./app/brds/AddRoute_1"));
const LzUsrConfiguration = React.lazy(() => import("./app/brds/UserConfiguration"));
const LzUsrConfiguration_1 = React.lazy(() => import("./app/brds/UserConfiguration_1"));
const LzVhclMstr = React.lazy(() => import("./app/hcf/VhclMstr"));
const LzVhclMstr_1 = React.lazy(() => import("./app/hcf/VhclMstr_1"));
const LzHelpdesk = React.lazy(() => import("./components/helpdesk/helpdesk"));
const LzMonthlyReports = React.lazy(() => import("./app/Reports/MonthlyReports"));
const LzMonthlyReports_1 = React.lazy(() => import("./app/Reports/MonthlyReports_1"));
const LzHdr = React.lazy(() => import("./app/Hdr"));
const LzHdrState = React.lazy(() => import('./app/HdrState'));
const LzWtrUserwst = React.lazy(() => import("./app/brds/WtrUserwst"));
const LzWtrUserwst_1 = React.lazy(() => import("./app/brds/WtrUserwst_1"));
const LzWtrUploadwstFile_1 = React.lazy(()=>import("./app/brds/WtrUploadwstFile_1"))
const LzChangePasswod = React.lazy(() => import("./app/brds/ChangePasswod"));
const LzChangePasswod_1 = React.lazy(() => import("./app/brds/ChangePasswod_1"));
const LzPrintLbl = React.lazy(() => import("./app/Reports/PrintLbl"));
const LzPrintLbl_1 = React.lazy(() => import("./app/Reports/PrintLbl_1"));
const LzDailySummaryFactory = React.lazy(() => import("./app/Reports/DailySummaryFactory"));
const LzDailySummaryFactoryComp = React.lazy(() => import("./app/Reports/DailySummaryFactoryComp"));

const LzDailySummaryFactoryNotScn = React.lazy(() => import("./app/Reports/DailySummaryFactoryNotScn"));
const LzDailySummaryFactoryNotScn_1 = React.lazy(() => import("./app/Reports/DailySummaryFactoryNotScn_1"));
const LzDailySummaryFactory_1 = React.lazy(() => import("./app/Reports/DailySummaryFactory_1"));
const LzDailySummary = React.lazy(() => import("./app/Reports/DailySummary"));
const LzDailySummary_1 = React.lazy(() => import("./app/Reports/DailySummary_1"));
const LzBagCntPrHr = React.lazy(() => import("./app/Reports/BagCntPrHr"));
const LzBagCntPrHr_1 = React.lazy(() => import("./app/Reports/BagCntPrHr_1"));
const LzMonthlyReportAllHcf = React.lazy(() => import("./app/Reports/MonthlyReportAllHcf"));
const LzMonthlySummary = React.lazy(() => import("./app/Reports/MonthlyReportAllHcf"));
const LzAnnualCbwtf = React.lazy(() => import("./app/Reports/AnnualCbwtf"));
const LzAnnualCbwtf_1 = React.lazy(() => import("./app/Reports/AnnualCbwtf_1"));
const LzTrackingBmw = React.lazy(() => import("./app/Reports/TrackingBmw"));
const LzHcfWiseReport = React.lazy(() => import("./app/brds/HcfWiseReport"));
const LzHcfCbwtfReport = React.lazy(() => import("./app/brds/HcfCbwtfReport"));
const LzEmlsnt = React.lazy(() => import("./app/Reports/emlsnt"));
const LzEmlsnt_1 = React.lazy(() => import("./app/Reports/emlsnt_1"));
const LzDailyReports = React.lazy(() => import("./app/Reports/DailyReports"));
const LzDailyReports_1 = React.lazy(() => import("./app/Reports/DailyReports_1"));
const LzDailyReportsAckwldge_1 = React.lazy(() => import("./app/Reports/DailyReportsAcklwdge_1 "));

const LzNrjLgn = React.lazy(() => import("./login/NrjLogin"));
const LzNrjLgn_1 = React.lazy(() => import("./login/NrjLogin_1"));
const LzNrjAutoLogout_1 = React.lazy(() => import("./login/AutoLogout_1"));
const LzDshBrd = React.lazy(() => import("./app/dshbrd/Dashbrd"));
const LzDshBrd_1 = React.lazy(() => import("./app/dshbrd/Dashbrd_1"));
const LzWtrPltngnrMn = React.lazy(() => import("./app/brds/WtrPltngnrMn"));
const LzWtrPltngnrMned = React.lazy(() => import("./app/brds/WtrPltngnrMned"));
const LzWtrPltngnrMned_1 = React.lazy(() => import("./app/brds/WtrPltngnrMned_1"));
const LzWtrPltngnrMn_1 = React.lazy(() => import("./app/brds/WtrPltngnrMn_1"));
const LzHospitalRoute = React.lazy(() => import("./app/brds/HospitalRoute"));
const LzHospitalRoute_1 = React.lazy(() => import("./app/brds/HospitalRoute_1"));
const LzAggregator = React.lazy(() => import("./app/brds/Aggregator"));
const LzPrintHCFBar = React.lazy(() => import("./app/Reports/PrintHCFBarCde"));
const LzMisBagcount = React.lazy(() => import("./app/Reports/MisBagCountLbl"))
const LzMisBagcount_1 = React.lazy(() => import("./app/Reports/MisBagCountLbl_1"))
const LzMisBagcntwthGeo = React.lazy(() => import("./app/Reports/MisBagCntwthGeo"))
const LzMisBagcntwthGeo_1 = React.lazy(() => import("./app/Reports/MisBagCntwthGeo_1"))
const LzHcfNmbrVisited = React.lazy(() => import("./app/Reports/MisHcfNmbrVisited"));
const LzHcfNmbrVisited_1 = React.lazy(() => import("./app/Reports/MisHcfNmbrVisited_1"));
const LzHcfNotVstd_1 = React.lazy(() => import("./app/Reports/MisHcfNonVisited_1"));
const LzHcfNotVstd = React.lazy(() => import("./app/Reports/MisHCFNotVstd"));
const LzMonthlyHCFsts = React.lazy(() => import("./app/Reports/MonthlyHCFsts"));
const LzMonthlyHCFsts_1 = React.lazy(() => import("./app/Reports/MonthlyHCFsts_1"));
const LzHcfDatetoDate = React.lazy(() => import("./app/Reports/HcfDatetoDate"));
const LzWtrIncompleteHcf = React.lazy(() => import('./app/brds/IncompleteHcf'))
const LzWtrIncompleteHcf_1 = React.lazy(() => import('./app/brds/IncompleteHCF_1'))
const LzBigBag = React.lazy(() => import("./app/Reports/BigBag"))
const LzBigBag_1 = React.lazy(() => import("./app/Reports/BigBag_1"))
const LzHcfCtgCnt = React.lazy(() => import("./app/Reports/HcfCtgCnt"))
const LzHcfCtgCnt_1 = React.lazy(() => import("./app/Reports/HcfCtgCnt_1"))
const LzHcfCtgCntGrid = React.lazy(() => import("./app/Reports/HcfCtgCntGrid"))
const LzHcfCtgCntGrid_1 = React.lazy(() => import("./app/Reports/HcfCtgCntGrid_1"))
const LzBhuvanMap = React.lazy(() => import('./app/Reports/BhuvanMap'));
const LzHelpPage = React.lazy(() => import('./app/Reports/HelpPage'))
const LzBagsfrdt = React.lazy(() => import("./app/hcf/Bagsfrdt"))
const LzFndusr = React.lazy(() => import("./app/admin/Fndusr"))
const LzStateDashboard = React.lazy(() => import('./app/Reports/StateDashboard'))
const LzCbwtfLst = React.lazy(() => import('./app/Reports/CbwtfLst'));
const LzPrePrnLbl = React.lazy(() => import('./app/Reports/PrePrnLbl'));
const LzDownloadCert = React.lazy(() => import('./app/Reports/DownloadCert'))
const LzFileUploader = React.lazy(() => import('./app/Reports/FileUploader'))
const LzPrevDashboard = React.lazy(() => import('./app/dshbrd/DashbrdPrevDate'))
const LzPrevDashboard_1 = React.lazy(() => import('./app/dshbrd/DashbrdPrevDate_1'))
const LzStt_Telgn = React.lazy(() => import('./app/admin/Stt_Telgn'))
const LzHcfMstr = React.lazy(() => import('./app/Reports/hcf_mstr'))
const LzSttHcfVisited = React.lazy(() => import('./app/Reports/SttHcfVisited'))
const LzSttHcfVisited_1 = React.lazy(() => import('./app/Reports/SttHcfVisited_1'))
const LzSttMissingRoute = React.lazy(() => import('./app/Reports/MissingRoute'))
const LzSttMissingRoute_1 = React.lazy(() => import('./app/Reports/MissingRoute_1'))
const LzPrntLblCnt = React.lazy(() => import('./app/Reports/PrntLblCnt'))
const LzPrntLblCnt_1 = React.lazy(() => import('./app/Reports/PrntlblCnt_1'))
const LzDailySummaryRoute = React.lazy(() => import('./app/Reports/DailySummaryRoute'))
const LzDailySummaryComponent = React.lazy(() => import('./app/Reports/DailySummaryComponent'));
const LzSummaryHcf = React.lazy(() => import('./app/brds/SummaryHcf'));
const LzSummaryHcf_1 = React.lazy(() => import('./app/brds/SummaryHcf_1'));
const LzGoogleRouteVehicle = React.lazy(() => import('./app/Reports/GoogleRouteVehicle'));
const LzTrackMyFleet = React.lazy(() => import('./app/mstr/TrackMyFleet'))
const LzTrackMyFleet_1 = React.lazy(() => import('./app/mstr/TrackMyFleet_1'))
const LzHCFByAgreementDate = React.lazy(() => import('./app/brds/HCFByAgreementDate'))
const LzUsrRout = React.lazy(() => import('./app/Reports/UserRoute'))
const LzUsrRout_1 = React.lazy(() => import('./app/Reports/UserRoute_1'))
const LzSupVsrRout = React.lazy(() => import('./app/Reports/SuperVisorRoute'))
const LzSupVsrRout_1 = React.lazy(() => import('./app/Reports/SuperVisorRoute_1'))
const LzQrCodeSummary = React.lazy(() => import('./app/Reports/QrCodeSummary'))
const LzQrCodeSummary_1 = React.lazy(() => import('./app/Reports/QrCodeSummary_1'))
const LzDuplLblScn = React.lazy(() => import('./app/Reports/DuplicateLabelScan'))
const LzDuplLblScn_1 = React.lazy(() => import('./app/Reports/DuplicateLabelScan_1'))
const LzDailyReportAllRtu = React.lazy(() => import('./app/Reports/DailyReportAllRts'))
const LzDailyReportAllRtu_1 = React.lazy(() => import('./app/Reports/DailyReportAllRts_1'))
const LzWasteBagDelete = React.lazy(() => import('./app/brds/WasteBagDelete'))
const LzWasteBagDelete_1 = React.lazy(() => import('./app/brds/WasteBagDelete_1'))
const LzHCFNotVstMonth = React.lazy(() => import('./app/Reports/HCFNotVstMonth'))
const LzHCFNotVstMonth_1 = React.lazy(() => import('./app/Reports/HCFNotVstMonth_1'))
const LzMonthlyReportRouteWise = React.lazy(() => import('./app/Reports/MonthlyReportRouteWise'))
const LzMonthlyReportRouteWise_1 = React.lazy(() => import('./app/Reports/MonthlyReportRouteWise_1'))
const LzHcfSrchByCty = React.lazy(() => import('./app/brds/HcfSearchByCity'))
const LzHcfSrchByCty_1 = React.lazy(() => import('./app/brds/HcfSearchByCity_1'))
const LzChangeWstBgData = React.lazy(() => import('./app/Reports/ChangeWstBgData'))
const LzMonthlySummaryHCF = React.lazy(() => import("./app/Reports/MonthlySmryHCFWs"))
const LzMonthlySummaryHCF_1 = React.lazy(() => import("./app/Reports/MonthlySmryHCFWs_1"))
const LzAckMails = React.lazy(() => import("./app/hcf/AckMails"))
const LzAckMails_1 = React.lazy(() => import("./app/hcf/AckMails_1"))

function App() {
  let hst: string = window.location.href;
  const [state, setState] = useState<string>('Active')
  const [count, setCount] = useState<number>(0)
  const [remaining, setRemaining] = useState<number>(0)
  hst = hst.toLocaleLowerCase()
  if (hst.indexOf("vnent") > -1) {
    document.title = 'BMW'
    SetApp("1")

  } else if (hst.indexOf("local") > -1) {
    document.title = 'Neerja Asscociates'
    SetApp("_1")
  } else {
    document.title = 'BMW'
    SetApp("_1")
  }

  function signout() {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  }

  const onIdle = () => {
    setState('Idle')
    signout();
  }

  const onActive = () => {
    setState('Active')
  }

  const onAction = () => {
    setCount(count + 1)
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1500000,// 25 मिनट  20 minute 1200000
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  // document.title = 'Neerja Associates'
  // SetApp("_1")
  const navigate = useNavigate();

  const location = useLocation();
  const stateLogin: boolean = isStateLogin();
  let cbwtfidForState: string = sessionStorage.getItem("statebrdcbwtf") || "1";

  const [cbwtfid, setCbwtfId] = useState(cbwtfidForState == '1' ? "" : '1');
  const openHelpPage = () => {
    let pdfUrl = 'pdf/' + window.location.pathname.replace('/', '') + '.pdf';
    pdfUrl = pdfUrl.replace('_1', '')
    window.open(pdfUrl, '_blank');
  };
  const setCnfg = (dataCnfgn: any) => {
    let dt = GetResponseWnds(dataCnfgn);
    if (dt) {
      setConfig(dt)
    }
  }
  const [appConfig, setAppConfig] = useState<any>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const appConfigData = await fetchAppConfiguration();
        setCnfg(appConfigData)
        setAppConfig(appConfigData);
      } catch (error) {
        console.error('Error fetching configuration data:', error);
        navigate('/')
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    if (window.location.href.indexOf("localhost") > -1) {
      return;
    }
    if (window.location.protocol == "http:") {
      window.location.href = window.location.href.replace("http:", "https:")
    }
  }, []);

  if (!appConfig && location.pathname == '/') {

    return <LoadingComponent />;
  }

  return (
    <div className='bg-white'>
      <div className="sticky top-0" style={{ zIndex: "1000" }}>
        {stateLogin ? <LzHdrState cbwtfid={cbwtfid} setcbwtfid={(data: string) => { setCbwtfId(data) }} /> : <LzHdr />}
      </div>
      {/* <div className="rounded my-8" style={{ boxShadow: '0px 0px 20px 0px #00000029' }}>
        <div className="rounded-lg mx-12 pb-12"> */}

          {/* {Hdr_1()} */}
          <Hdr_1>
         {/* { window.location.href == 'https://swachhta.org/' ? <LzNrjAutoLogout_1 /> :<></>} */}
          {stateLogin ?
          
            <Routes>
              <Route
                path="/"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}>
                  <Loader size="lg" content="Loading..." style={loaderStyle} />
                </div>}>
                    {sessionStorage.getItem('formate') == '_1' ? <LzNrjLgn_1 /> : <LzNrjLgn />}
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="forgotPassword"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzNrjForgotPassword />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="hospitalRoute"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHospitalRoute />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>


              <Route
                path="/hcfnotvstd"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzHcfNotVstd></LzHcfNotVstd>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/monthlyHcfStatus"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzMonthlyHCFsts></LzMonthlyHCFsts>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="dshb"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDshBrd></LzDshBrd>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/sttDashboard"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzStateDashboard setcbwtfid={(data: string) => { setCbwtfId(data) }}></LzStateDashboard>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="vehicleRoute"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzGoogleRouteVehicle />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/sttDashboardCbwtf"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDshBrd></LzDshBrd>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/downloadCert"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDownloadCert></LzDownloadCert>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailyReport"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReports />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/summaryHcf"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzSummaryHcf></LzSummaryHcf>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/summaryHcf_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzSummaryHcf_1></LzSummaryHcf_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/hcfMstr"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHcfMstr></LzHcfMstr>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/stthcfvisited"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzSttHcfVisited></LzSttHcfVisited>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/stthcfvisited_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzSttHcfVisited_1></LzSttHcfVisited_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/hcfVisited"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzHcfNmbrVisited></LzHcfNmbrVisited>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/hcfVisited_1"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzHcfNmbrVisited_1></LzHcfNmbrVisited_1>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/sttMissingRoute"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzSttMissingRoute></LzSttMissingRoute>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/cbwtfLst"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzCbwtfLst setcbwtfid={(data: string) => { setCbwtfId(data) }}></LzCbwtfLst>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/bigBag"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzBigBag></LzBigBag>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailysummaryfactoryComp"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummaryFactory hideComp={false} />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailysummaryfactoryComp_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummaryFactory_1 hideComp={false} />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailysummaryfactory"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummaryFactory hideComp={true} />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              {/* <Route
              path="/dailysummaryfactorynotscn"
              element={
                <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                  <NrjRequire>
                    <LzDailySummaryFactoryNotScn />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route> */}
              <Route
                path="/dailysummaryfactorynotscn_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummaryFactoryNotScn_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailysummary"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummary />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/monthlyReport"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlyReports />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="monthlyReportAllHcf"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlyReportAllHcf />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/emlsnt"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzEmlsnt />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/labelInf"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzBarCode></LzBarCode>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>


              <Route
                path="ctgCountChart"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHcfCtgCnt />
                    </NrjRequire>

                  </React.Suspense>
                }
              ></Route>
              <Route
                path="ctgCountChart_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHcfCtgCnt_1 />
                    </NrjRequire>

                  </React.Suspense>
                }
              ></Route>
              <Route
                path="ProperWstbg"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzProperWstbg />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/monthlySummary"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlySummary></LzMonthlySummary>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="ImProperWstbg"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzImProperWstbg />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="dailyAckDifference"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummaryComponent colDef={[
                        { field: 'id', hide: true, width: 0, headerName: '' },
                        { field: 'hcfcod', hide: false, width: 120, headerName: 'SPCB Code' },
                        { field: 'hcfnm', hide: false, width: 250, headerName: 'HCF' },
                        { field: 'nbd', hide: showFieldInColdef('shwbd'), width: 120, headerName: 'No Of Beds' },
                        { field: 'redwt_ackdiff', hide: false, width: 100, headerName: 'Red(Bags)', cellStyle: { color: 'black', 'background-color': '#ffcccb' } },
                        { field: 'redwt_ackdiff', hide: false, width: 150, headerName: 'Red(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#ffcccb' } },
                        { field: 'ylwcnt_ackdiff', hide: false, width: 100, headerName: 'Yellow(Bags)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'ylwwt_ackdiff', hide: false, width: 150, headerName: 'Yellow(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'whtcnt_ackdiff', hide: false, width: 100, headerName: 'White(Bags)' },
                        { field: 'whtwt_ackdiff', hide: false, width: 150, headerName: 'White(Kg/gms)' },
                        { field: 'blucnt_ackdiff', hide: false, width: 100, headerName: 'Blue(Bags)', cellStyle: { color: 'black', 'background-color': '#ADD8E6' } },
                        { field: 'bluwt_ackdiff', hide: false, width: 150, headerName: 'Blue(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#ADD8E6' } },
                        { field: 'cytcnt_ackdiff', hide: false, width: 100, headerName: 'Cytotoxic(Bags)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'cytwt_ackdiff', hide: false, width: 150, headerName: 'Cytotoxic(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'bsnt', hide: false, width: 350, headerName: 'Bags sent to CPCB Server' },
                        { field: 'bdst', hide: false, width: 400, headerName: 'Bags scanned from correct distance' },
                      ]}
                        apiData={{
                          db: "",
                          dll: "",
                          fnct: "",
                          callFurtherApi: false,
                          db2: "",
                          dll2: "",
                          fnct2: "",
                          prntdb: "",
                          prntdll: "",
                          prntfnct: "",
                        }}
                      />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="unsentData"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzUnsentData />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/user"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWtrUserwst />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/PrintLbl"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzPrintLbl />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/userConfiguration"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzUsrConfiguration></LzUsrConfiguration>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="changePassword"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzChangePasswod />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/liveLocation"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzGoogleMapLive />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/liveLocation_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzGoogleMapLive_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="userRoute"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzGoogleRouteUsers />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
            </Routes>
           

            // VN Login Route Here
            :

            <Routes>
              <Route
                path="bagsfrdt"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzBagsfrdt></LzBagsfrdt>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/smrmonthlyreport"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlyReports />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/smrmonthlyreport_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlyReports_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/AckMails"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzAckMails></LzAckMails>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/AckMails_1"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzAckMails_1 />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="wrongGeoLocation"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWrongGeoLocation></LzWrongGeoLocation>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="wrongGeoLocation"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWrongGeoLocation></LzWrongGeoLocation>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="wrongGeoLocation_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWrongGeoLocation_1></LzWrongGeoLocation_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/validatefct_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReportsFactory_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/validatefct"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReportsFactory />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="fileupload"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzFileUploader></LzFileUploader>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="prevDashboard"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzPrevDashboard></LzPrevDashboard>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="annualReport_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAnnualHospitalReport_1></LzAnnualHospitalReport_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="annualReport"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAnnualHospitalReport></LzAnnualHospitalReport>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="dailySummaryScnby"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <DailySummaryByScnby></DailySummaryByScnby>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="dailySummaryScnby_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <DailySummaryByScnby_1></DailySummaryByScnby_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="dailyReportScnby"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyDetailedScnby></LzDailyDetailedScnby>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="dailyReportScnby_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyDetailedScnby_1></LzDailyDetailedScnby_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="prevDashboard_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzPrevDashboard_1></LzPrevDashboard_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="chngwstbgdt"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzChangeWstBgData></LzChangeWstBgData>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>


              <Route
                path="fndusr"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzFndusr></LzFndusr>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="incompletePtlng"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWtrIncompleteHcf />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="incompletePtlng_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWtrIncompleteHcf_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="cbwtf"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAggregator />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/monthlyReportHcf"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlySummaryHCF />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/monthlyReportHcf_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlySummaryHCF_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="hlpmstr"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <Hlp_mstr />
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="hlpgrid"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <Hlp_grid />
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="ptlng"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWtrPltngnrMn />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="ptlngerrcpcb"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWtrPltngnrMn />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="ptlngerrcpcb_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWtrPltngnrMn_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="ptlnmngrduplicateSpcb"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWtrPltngnrMn />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="ptlnged"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWtrPltngnrMned />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="preprnlbl"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzPrePrnLbl></LzPrePrnLbl>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="printLblCnt"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzPrntLblCnt></LzPrntLblCnt>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="printLblCnt_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzPrntLblCnt_1></LzPrntLblCnt_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/stt_telgn"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzStt_Telgn></LzStt_Telgn>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="ptlnged_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWtrPltngnrMned_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="dshb"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDshBrd></LzDshBrd>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="dshb_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDshBrd_1></LzDshBrd_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="ptlng_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}>
                  <Loader size="lg" content="Loading..." style={loaderStyle} />
                </div>}>
                    <NrjRequire>
                      <LzWtrPltngnrMn_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="hospitalRoute"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHospitalRoute />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="hospitalRoute_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHospitalRoute_1 />
                    </NrjRequire>

                  </React.Suspense>
                }
              ></Route>

              <Route
                path="hcfsrchbycty"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHcfSrchByCty />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="hcfsrchbycty_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHcfSrchByCty_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="ctgCountChart"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHcfCtgCnt />
                    </NrjRequire>

                  </React.Suspense>
                }
              ></Route>
              <Route
                path="ctgCount"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHcfCtgCntGrid />
                    </NrjRequire>

                  </React.Suspense>
                }
              ></Route>
              <Route
                path="ctgCount_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHcfCtgCntGrid_1 />
                    </NrjRequire>

                  </React.Suspense>
                }
              ></Route>
              <Route
                path="ctgCountChart_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHcfCtgCnt_1 />
                    </NrjRequire>

                  </React.Suspense>
                }
              ></Route>

              <Route
                path="addRoute"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAddRoute></LzAddRoute>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="addRoute_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAddRoute_1></LzAddRoute_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="usrroute"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzUsrRout></LzUsrRout>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="usrroute_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzUsrRout_1></LzUsrRout_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="supvsrroute"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzSupVsrRout></LzSupVsrRout>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="supvsrroute_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzSupVsrRout_1></LzSupVsrRout_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="qrcode"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzQrCodeSummary></LzQrCodeSummary>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="qrcode_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzQrCodeSummary_1></LzQrCodeSummary_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="qrcodeWithDate"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzQrCodeSummary showDate={true}></LzQrCodeSummary>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="qrcodeWithDate_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzQrCodeSummary_1 showDate={true}></LzQrCodeSummary_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/user"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWtrUserwst />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/user_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWtrUserwst_1 />
                    </NrjRequire>

                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/uploadFile_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWtrUploadwstFile_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/userConfiguration"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzUsrConfiguration></LzUsrConfiguration>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/userConfiguration_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzUsrConfiguration_1></LzUsrConfiguration_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/cbwtf_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAggregator_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="changePassword"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzChangePasswod />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="changePassword_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzChangePasswod_1 />
                    </NrjRequire>

                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/monthlyReport"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlyReports />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/monthlySummary"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlySummaryReport />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/monthlySummary_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlySummaryReport_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/monthlyReport_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlyReports_1 />
                    </NrjRequire>

                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/hcfnotvstmnth"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHCFNotVstMonth />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/hcfnotvstmnth_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHCFNotVstMonth_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/mthlyreprtuntvst"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlyReportRouteWise visite={'1'} />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/mthlyreprtu"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlyReportRouteWise visite={'0'} />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/mthlyreprtuntvst_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlyReportRouteWise_1 visite={'1'} />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/mthlyreprtu_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlyReportRouteWise_1 visite={'0'} />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/dailyReport"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReports />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/dailyReportAllRoute"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReports showRoute={true} />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailyReportAllRoute_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReports_1 showRoute={true} />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailyReportwstbgcpcb"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReports />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailyReportwstbgcpcb_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReports_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailyReportntwstbgcpcb"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReports />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailyReportntwstbgcpcb_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReports_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/summaryHcf"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzSummaryHcf></LzSummaryHcf>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/summaryHcf_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzSummaryHcf_1></LzSummaryHcf_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailyReport_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReports_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailyReportAckwldge_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReportsAckwldge_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailyRouteAll"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReportAllRtu />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailyRouteAll_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReportAllRtu_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/wstbgdel"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWasteBagDelete />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route><Route
                path="/wstbgdel_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWasteBagDelete_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/PrintLbl"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzPrintLbl />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/PrintLbl_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzPrintLbl_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/PrintCertificate"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzPrintLbl />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/PrintCertificate_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzPrintLbl_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/dupllblscn"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDuplLblScn coldef={[
                        { field: 'id', hide: true, width: 0, headerName: '' },
                        { field: 'hcfid', hide: true, width: 180, headerName: 'Code' },
                        { field: 'hcf', hide: false, width: 450, headerName: 'HCF' },
                        { field: 'lblno', hide: false, width: 250, headerName: 'Lable Number' },
                        { field: 'dt_scn', hide: false, width: 300, headerName: 'Date of Collection' },
                      ]}

                        apiCall="nodb=rowset=fnct=s=c118=[cmpid]=[usrid]=A=[dt_frm]=[dt_to]=[gid]"
                        prntApi='nodb=rowset=fnct=chqdll=g127=[cmpid]=[usrid]=A=16=[gid]=[dt_frm]=[dt_to]'
                      />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dupllblscn_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDuplLblScn_1 coldef={[
                        { field: 'id', hide: true, width: 0, headerName: '' },
                        { field: 'hcfid', hide: true, width: 180, headerName: 'Code' },
                        { field: 'hcf', hide: false, width: 450, headerName: 'HCF' },
                        { field: 'lblno', hide: false, width: 250, headerName: 'Label Number' },
                        { field: 'dt_scn', hide: false, width: 300, headerName: 'Date of Collection' },
                      ]}
                        apiCall="nodb=rowset=fnct=s=c118=[cmpid]=[usrid]=A=[dt_frm]=[dt_to]=[gid]"
                      />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/emlsnt"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzEmlsnt />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/emlsnt_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzEmlsnt_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/hfcWiseReport"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHcfWiseReport />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/hcfCbwtfReport"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHcfCbwtfReport />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              {/* <Route
            path="/cpchhopg"
            element={
              <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                <NrjRequire>
                  <CpchHoPg />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route> */}

              <Route
                path="/dailyreportfactory"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReportsFactory />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailyreportfactory_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailyReportsFactory_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailysummaryfactoryComp"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      {/* <LzDailySummaryFactory hideComp ={false}/> */}
                      <LzDailySummaryFactoryComp hideComp={false} />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailysummaryfactory"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummaryFactory hideComp={true} />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              {/* <Route
              path="/dailysummaryfactorynotscn"
              element={
                <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                  <NrjRequire>
                    <LzDailySummaryFactoryNotScn />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route> */}
              <Route
                path="/dailysummaryfactorynotscn_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummaryFactoryNotScn_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/dailysummaryfactoryComp_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummaryFactory_1 hideComp={false} />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/dailysummaryfactory_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummaryFactory_1 hideComp={true} />
                    </NrjRequire>

                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/dailysummary"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummary />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/dailysummary_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummary_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/hospitalDisplay"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHCFDisplay></LzHCFDisplay>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/hospitalDisplay_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHCFDisplay_1></LzHCFDisplay_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/cbwtfDisplay"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzCbwtfDisplay />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/wasteDisplay"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWstbgDisplay />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              {/* <Route
            path="/centralisedtrackingbmw"
            element={
              <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                <NrjRequire>
                  <LzCbwtfReportedList />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>   */}

              <Route
                path="monthlyReportAllHcf"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlyReportAllHcf />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="monthlyReportAllHcf_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzMonthlyReportAllHcf_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="annualCbwtf"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAnnualCbwtf />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="ProperWstbg"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzProperWstbg />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="ProperWstbg_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzProperWstbg_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="ImProperWstbg"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzImProperWstbg />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="ImProperWstbg_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzImProperWstbg_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="testingWasteBags"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzTestingWasteBag />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="testingWasteBags_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzTestingWasteBag_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="annualCbwtf_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAnnualCbwtf_1 />
                    </NrjRequire>

                  </React.Suspense>
                }
              ></Route>
              <Route
                path="hcfCountByState"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHcfCount
                        groupBy={"stt"}
                        cols={[
                          { field: "stt", width: 100, headerName: "State" },
                          {
                            field: "hcfcount",
                            width: 150,
                            headerName: "HCF Count",
                          },
                        ]}
                      ></LzHcfCount>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="hcfCountBycbwtfid"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHcfCount
                        groupBy={"cbwtfid"}
                        cols={[
                          { field: "cbwtfid", width: 100, headerName: "CBWTF Id" },
                          {
                            field: "hcfcount",
                            width: 150,
                            headerName: "HCF Count",
                          },
                        ]}
                      ></LzHcfCount>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="hcfCountByRgd"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHcfCount
                        groupBy={"rgd"}
                        cols={[
                          {
                            field: "rgd",
                            width: 100,
                            headerName: "R D"
                          },
                          {
                            field: "hcfcount",
                            width: 150,
                            headerName: "HCF Count",
                          },
                        ]}
                      ></LzHcfCount>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="trackingbmw"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzTrackingBmw />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="duplicateSpcb"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDuplicateSpcb />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="duplicateSpcb_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDuplicateSpcb_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="unsentData"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzUnsentData />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="unsentData_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzUnsentData_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="vehicleRoute"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzGoogleRouteVehicle />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>


              <Route
                path="openModel"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDataCorrection />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="wasteSummary"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummaryComponent colDef={[
                        { field: 'id', hide: true, width: 0, headerName: '' },
                        { field: 'cbwtf', hide: false, width: 150, headerName: 'SPCB Code' },
                        { field: 'cntr', hide: false, width: 250, headerName: 'HCF' },
                        { field: 'nbd', hide: showFieldInColdef('shwbd'), width: 160, headerName: 'No Of Beds' },
                        { field: 'tbag', hide: false, width: 180, headerName: 'Total bags' },
                        { field: 'bsnt', hide: false, width: 350, headerName: 'Total bags sent to CPCB Server' },
                        { field: 'bdst', hide: false, width: 400, headerName: 'Total bags scanned from correct distance' },
                        { field: 'tbagf', hide: false, width: 300, headerName: 'Bags Scanned by Factory' },
                        { field: 'fsnt', hide: false, width: 250, headerName: 'Factory bags sent to CPCB Server' },
                        { field: 'fdst', hide: false, width: 250, headerName: 'Factory bags sent from correct distance' }
                      ]}
                        apiData={{
                          db: "",
                          dll: "",
                          fnct: "",
                          callFurtherApi: false,
                          db2: "",
                          dll2: "",
                          fnct2: "",
                          prntdb: "",
                          prntdll: "",
                          prntfnct: "",
                        }}
                      />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="dailySummaryDistrict"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummaryComponent colDef={[
                        { field: 'id', hide: true, width: 0, headerName: '' },
                        { field: 'cbwtf', hide: false, width: 150, headerName: 'SPCB Code' },
                        { field: 'cntr', hide: false, width: 250, headerName: 'HCF' },
                        { field: 'nbd', hide: showFieldInColdef('shwbd'), width: 120, headerName: 'No Of Beds' },
                        { field: 'rcnt', hide: false, width: 100, headerName: 'Red(Bags)', cellStyle: { color: 'black', 'background-color': '#ffcccb' } },
                        { field: 'rwt', hide: false, width: 150, headerName: 'Red(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#ffcccb' } },
                        { field: 'ycnt', hide: false, width: 100, headerName: 'Yellow(Bags)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'ywt', hide: false, width: 150, headerName: 'Yellow(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'wcnt', hide: false, width: 100, headerName: 'White(Bags)' },
                        { field: 'wwt', hide: false, width: 150, headerName: 'White(Kg/gms)' },
                        { field: 'bcnt', hide: false, width: 100, headerName: 'Blue(Bags)', cellStyle: { color: 'black', 'background-color': '#ADD8E6' } },
                        { field: 'bwt', hide: false, width: 150, headerName: 'Blue(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#ADD8E6' } },
                        { field: 'ccnt', hide: false, width: 100, headerName: 'Cytotoxic(Bags)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'cwt', hide: false, width: 150, headerName: 'Cytotoxic(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'tcnt', hide: false, width: 100, headerName: 'Total Bags' },
                        { field: 'twt', hide: false, width: 150, headerName: 'Total(Kg/gms)' },
                        { field: 'bsnt', hide: false, width: 350, headerName: 'Bags sent to CPCB Server' },
                        { field: 'bdst', hide: false, width: 400, headerName: 'Bags scanned from correct distance' },
                      ]}
                        apiData={{
                          db: "",
                          dll: "",
                          fnct: "",
                          callFurtherApi: false,
                          db2: "",
                          dll2: "",
                          fnct2: "",
                          prntdb: "",
                          prntdll: "",
                          prntfnct: "",
                        }}
                      />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="dailySummaryVehicle"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummaryComponent colDef={[
                        { field: 'id', hide: true, width: 0, headerName: '' },
                        { field: 'cbwtf', hide: false, width: 150, headerName: 'SPCB Code' },
                        { field: 'cntr', hide: false, width: 250, headerName: 'HCF' },
                        { field: 'nbd', hide: showFieldInColdef('shwbd'), width: 120, headerName: 'No Of Beds' },
                        { field: 'rcnt', hide: false, width: 100, headerName: 'Red(Bags)', cellStyle: { color: 'black', 'background-color': '#ffcccb' } },
                        { field: 'rwt', hide: false, width: 150, headerName: 'Red(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#ffcccb' } },
                        { field: 'ycnt', hide: false, width: 100, headerName: 'Yellow(Bags)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'ywt', hide: false, width: 150, headerName: 'Yellow(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'wcnt', hide: false, width: 100, headerName: 'White(Bags)' },
                        { field: 'wwt', hide: false, width: 150, headerName: 'White(Kg/gms)' },
                        { field: 'bcnt', hide: false, width: 100, headerName: 'Blue(Bags)', cellStyle: { color: 'black', 'background-color': '#ADD8E6' } },
                        { field: 'bwt', hide: false, width: 150, headerName: 'Blue(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#ADD8E6' } },
                        { field: 'ccnt', hide: false, width: 100, headerName: 'Cytotoxic(Bags)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'cwt', hide: false, width: 150, headerName: 'Cytotoxic(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'tcnt', hide: false, width: 100, headerName: 'Total Bags' },
                        { field: 'twt', hide: false, width: 150, headerName: 'Total(Kg/gms)' },
                        { field: 'bsnt', hide: false, width: 350, headerName: 'Bags sent to CPCB Server' },
                        { field: 'bdst', hide: false, width: 400, headerName: 'Bags scanned from correct distance' },
                      ]}
                        apiData={{
                          db: "",
                          dll: "",
                          fnct: "",
                          callFurtherApi: false,
                          db2: "",
                          dll2: "",
                          fnct2: "",
                          prntdb: "",
                          prntdll: "",
                          prntfnct: "",
                        }}
                      />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="hcfCompare"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzDailySummaryComponent colDef={[
                        { field: 'id', hide: true, width: 0, headerName: '' },
                        { field: 'dt_wst', hide: false, width: 100, headerName: 'Date' },
                        { field: 'hsp', hide: false, width: 250, headerName: 'HCF' },
                        { field: 'nbd', hide: showFieldInColdef('shwbd'), width: 120, headerName: 'No Of Beds' },
                        { field: 'rcnt', hide: false, width: 100, headerName: 'Red(Bags)', cellStyle: { color: 'black', 'background-color': '#ffcccb' } },
                        { field: 'rwt', hide: false, width: 150, headerName: 'Red(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#ffcccb' } },
                        { field: 'ycnt', hide: false, width: 100, headerName: 'Yellow(Bags)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'ywt', hide: false, width: 150, headerName: 'Yellow(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'wcnt', hide: false, width: 100, headerName: 'White(Bags)' },
                        { field: 'wwt', hide: false, width: 150, headerName: 'White(Kg/gms)' },
                        { field: 'bcnt', hide: false, width: 100, headerName: 'Blue(Bags)', cellStyle: { color: 'black', 'background-color': '#ADD8E6' } },
                        { field: 'bwt', hide: false, width: 150, headerName: 'Blue(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#ADD8E6' } },
                        { field: 'ccnt', hide: false, width: 100, headerName: 'Cytotoxic(Bags)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'cwt', hide: false, width: 150, headerName: 'Cytotoxic(Kg/gms)', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                        { field: 'tcnt', hide: false, width: 100, headerName: 'Total Bags' },
                        { field: 'twt', hide: false, width: 150, headerName: 'Total(Kg/gms)' },
                        { field: 'bsnt', hide: false, width: 350, headerName: 'Bags sent to CPCB Server' },
                        { field: 'bdst', hide: false, width: 400, headerName: 'Bags scanned from correct distance' },
                      ]}
                        apiData={{
                          db: "",
                          dll: "",
                          fnct: "",
                          callFurtherApi: false,
                          db2: "",
                          dll2: "",
                          fnct2: "",
                          prntdb: "sdf",
                          prntdll: "sdf",
                          prntfnct: "sdf",
                        }}
                      />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/map"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzGoogleMap />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/liveLocation"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzGoogleMapLive />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/liveLocation_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzGoogleMapLive_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="bhuvanmap"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzBhuvanMap />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="route"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzGoogleRoute />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="route_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzGoogleRoute_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="userRoute"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzGoogleRouteUsers />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="userRoute_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzGoogleRouteUsers_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="bhuvanroute"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzBhuvanRoute />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>


              <Route
                path="forgotPassword"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzNrjForgotPassword />
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="forgotPassword_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzNrjForgotPassword_1 />
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="cbwtfreportdisplay"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzCbwtfReportDisplay />
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="cbwtfreportdisplay_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzCbwtfReportDisplay_1 />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/VhclMstr"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzVhclMstr />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/VhclMstr_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzVhclMstr_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/labelInf"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzBarCode></LzBarCode>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/labelInf_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzBarCode_1></LzBarCode_1>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/edthcf"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzWtrPltngnrMn></LzWtrPltngnrMn>
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>



              <Route
                path="/annlrpt"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAnnlRpt />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/annlrpt_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAnnlRpt_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/annlwstwt"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAnnlWstWt />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/annlwstwt_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAnnlWstWt_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/annlwststrg"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAnnlWstStrg />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/annlwststrg_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAnnlWstStrg_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/annleqp"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAnnlEqp />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/annleqp_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAnnlEqp_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/annlmisc"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAnnlMisc />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/annlmisc_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzAnnlMisc_1 />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/helpPage"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <NrjRequire>
                      <LzHelpPage />
                    </NrjRequire>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/hcfVisited"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzHcfNmbrVisited></LzHcfNmbrVisited>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/hcfVisited_1"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzHcfNmbrVisited_1></LzHcfNmbrVisited_1>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailySummaryRoute"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzSummaryHcf></LzSummaryHcf>
                    {/* <LzDailySummaryRoute></LzDailySummaryRoute> */}
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/dailySummaryRoute_1"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzSummaryHcf_1></LzSummaryHcf_1>
                    {/* <LzDailySummaryRoute></LzDailySummaryRoute> */}
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/hcfnotvstd"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzHcfNotVstd></LzHcfNotVstd>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="stthcfvisited"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzSttHcfVisited></LzSttHcfVisited>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="stthcfvisited_1"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzSttHcfVisited_1></LzSttHcfVisited_1>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="sttMissingRoute"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzSttMissingRoute></LzSttMissingRoute>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="sttMissingRoute_1"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzSttMissingRoute_1></LzSttMissingRoute_1>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="vehicleHcfMap"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzVehicleHcfMap></LzVehicleHcfMap>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="vehicleHcfMap_1"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzVehicleHcfMap_1></LzVehicleHcfMap_1>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/hcfnotvstd_1"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzHcfNotVstd_1></LzHcfNotVstd_1>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/bigBag"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzBigBag></LzBigBag>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/bigBag_1"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzBigBag_1></LzBigBag_1>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/bagcountLbl"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzMisBagcount></LzMisBagcount>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/bagcountLbl_1"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzMisBagcount_1></LzMisBagcount_1>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/bagcntwthgeo"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzMisBagcntwthGeo></LzMisBagcntwthGeo>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/bagcntwthgeo_1"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzMisBagcntwthGeo_1></LzMisBagcntwthGeo_1>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/bagcountprhr"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzBagCntPrHr></LzBagCntPrHr>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/bagcountprhr_1"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzBagCntPrHr_1></LzBagCntPrHr_1>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/monthlyHcfStatus"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzMonthlyHCFsts></LzMonthlyHCFsts>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/trackmyfleet"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzTrackMyFleet></LzTrackMyFleet>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/trackmyfleet_1"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzTrackMyFleet_1></LzTrackMyFleet_1>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/hcfbyagreementdate"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzHCFByAgreementDate></LzHCFByAgreementDate>
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/monthlyHcfStatus_1"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzMonthlyHCFsts_1></LzMonthlyHCFsts_1>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/hcfupdated"
                element={
                 <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    <LzHcfDatetoDate></LzHcfDatetoDate>
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/"
                element={
                  <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                    {sessionStorage.getItem('formate') == '_1' ? <LzNrjLgn_1 /> : <LzNrjLgn />}
                  </React.Suspense>
                }
              ></Route>
              {/* <Route
              path="**"
              element={
                <React.Suspense fallback={<div style={loaderContainerStyle}><Loader size="lg" content="Loading..." style={loaderStyle} /></div>}>
                  <div>
                    <h3>Page not Found! </h3>
                  </div>
                </React.Suspense>
              }
            ></Route> */}

            </Routes>}
            </Hdr_1>

          {/* <Footer/> */}
        {/* </div>
      </div> */}

      <div>
        <Tooltip title="Help" arrow>
          <IconButton
            color="primary"
            onClick={openHelpPage}
            sx={{
              position: 'fixed',
              bottom: 40,
              right: 40,
              backgroundColor: '#007BFF',
              '&:hover': {
                backgroundColor: '#0056b3', // Change color on hover
              },
            }}
          >
            <HelpOutlineIcon style={{ color: 'white' }} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}


export default React.memo(App);
