export const pathNameHeading: any = {
    VhclMstr: "Vehicle Master",
    dshb: "Dashboard",
    ptlng: "Health Care Facility",
    ptlnged: "Health Care Facility Create Login And Verify",
    cbwtf: "Common Biomedical Waste Treatment Facility",
    user: "Users",
    changePassword: "Change Password",
    dailyReport: "Daily Waste Collection",
    dailyReportAllRoute : "Waste Collection Report : Daily Detailed (All Route)",
    monthlyReport: "Waste Collection Report : Monthly Report",
    hcfnotvisited: "List of Health Care Facility Not Visited",
    hfcWiseReport: "Health Care Facility Wise Report",
    dailysummary: "Waste Collection Report : Daily Summary",
    dailysummaryfactory: "Waste Bags Scanned At Plant",
    dailysummaryfactoryComp: "Waste Bags Scanned At Health Care Facility / Plant(Comparison)",
    hcfbedsdetails: "Health Care Facility-Beds Details",
    monthlyReportAllHcf: "Monthly Report : All Health Care Facility",
    monthlySummary: "Monthly Summary : All Health Care Facility",
    hcfCbwtfReport: "Health Care Facility - CBWTF Report",
    annualCbwtf: "Annual Report Common Biomedical Waste Treatment Facility",
    hospitalRoute: "Health Care Facility Route Information",
    userConfiguration: "User Configuration",
    addRoute: "Route Master",
    hospitaldisplay: "Browse Health Care Facility List",
    edthcf: "Edit Health Care Facility Details",
    prntlbl: "Print Labels",
    labelInf : "Barcode Information",
    route: 'Route',
    map: 'Locations',
    cbwtfreportdisplay: 'Statistics',
    emlsnt:'Acknowledgement For Waste Bags',
    ProperWstbg:'Succesfully Sent Waste Bags',
    ImProperWstbg: 'Waste Bags Not Sent to CPCB Server',
    PrintLbl:'Print Labels',
    unsentData: 'Unsend Health Care Facility',
    annlrpt: 'Annual Report',
    annlwstwt: 'Annual Waste Weight',
    annlwststrg: 'Annual Waste Storage',
    annleqp: 'Waste Equipment',
    annlmisc: 'Miscellaneous',
    hcfCountByState : "Health Care Facility-Count By State",
    hcfCountBycbwtfid : "Health Care Facility-Count By CBWTF-ID",
    hcfCountByRgd : "Health Care Facility-Count By Regional Directory",
    spcb_frst: "Information : pollution Control Board",
    spcb_cbwtf: "Information : CBWTF",
    spcb_auth: "Information : Authorize",
    spcb_dstr: "Informatoin : District",
    spcb_hcfcptv: "Information : Health Care Facility",
    AddMnMsg: "Add Message",
    bagcountprhr: "Bags Count Per Hour",
    hcfNmbrVisited: "No of Health Care Facility Visited",
    hcfnotvstd: "Health Care Facility Not Visited",
    bigBag: "Large Waste Bags",
    bagcountlbl: "Bags With Labels",
    bagcntwthgeo: "Bags With Location",
    monthlyHcfStatus: "Health Care Facility Monthly Status",
    hcfupdated: "Health Care Facility Updated Recently",
    incompletePtlng: "Incomplete Health Care Facility",
    ctgCount: 'Health Care Facility Types And Counts',
    ctgCountChart: 'Health Care Facility Category Wise Chart',
    bhuvanmap: 'Bhuvan Map Information',
    bagsfrdt : "Bags From Date",
    userRoute: 'Users : Route Details',
    hcfvisited: "Health Care Facility Attendance Marked",
    dailySummaryRoute: "Daily Report : Route Wise",
    testingWasteBags: "Waste Bags : Detailed Summary",
    vehicleSummary: "Daily Summary : Vehicle Wise",
    districtSummry: "Daily Summary : District Wise",
    hcfCompare: "Health Care Facility Comparative Analysis",
    CbwtfLst: ' List of Common Biomedical Waste Treatment Facility',
    hcfMstr: 'List of Health Care Facility',
    stthcfvisited: 'Health Care Facility Visited/Non-Visited',
    sttMissingRoute: 'Health Care Facility : Route Not Assigned',
    sttDashboard: 'Dashboard',
    sttDashboardCbwtf :"Dashboard",
    liveLocation: "Vehicle's Live Location",
    // dailysummaryfactorynotscn : "Waste Bags Not Scanned At Plant",
    trackmyfleet : "Authorize Track My Fleet",
    ptlngerrcpcb : "Unregistered At CPCB",
    ptlnmngrduplicateSpcb : "Duplicate SPCB Codes",
    prevDashboard: "Previous Dashboard",
    hospitalDisplay: "Health Care Facility Display",
    usrroute: "Daily Summary : Scanned By User",
    supvsrroute : "Daily Summary : Scanned At Plant",
    hcfVisited:"Health Care Facility Attendance Marked",
    summaryHcf : "Health Care Facility Summary",
    printLblCnt: "Print Labels Count",
    qrcode: "Label Summary",
    vehicleHcfMap: "Live Location With Health Care Facility",
    dupllblscn : "Duplicate Label Scan",
    dailySummaryScnby: "Daily Summary : Scan By Health Care Facility / Operator / Plant",
    dailyReportScnby: "Daily Detailed : Scan By Health Care Facility / Operator / Plant",
    hcfnotvstmnth: "Monthly Report : Health Care Facility Not Visited",
    dailyReportwstbgcpcb : 'Daily Report Of Waste Bags Sent To CPCB',
    dailyReportntwstbgcpcb : 'Daily Report Of Waste Bags Not Sent To CPCB',
    mthlyreprtuntvst : 'Monthly Report : Route Wise Not Visited',
    mthlyreprtu : 'Monthly Reportc: Route Wise',
    hcfsrchbycty : 'Health Care Facility In City',
    AckMails:'Acknowledgment Mails',
    duplicateSpcb:'Duplicate SPCB Codes',
    dailyRouteAll:'Daily Report : All Routes',
    validatefct:'Plant Waste Collection',
    smrmonthlyreport:' Monthly Summary Report',
    monthlyReportHcf:' Monthly Report : Health Care Facility Wise',
    annualReport:" Annual Report : Health Care Facility",
    wstbgdel:'Delete Waste Bags',
    PrintCertificate:'Registration Certificate',
    qrcodeWithDate:'Label Summary With Date',
    dailyreportfactory:'Plant Scannig Details',
    VhclMstr_1: "Vehicle Master",
    dshb_1: "Dashboard",
    ptlng_1: "Health Care Facility",
    ptlnged_1: "Health Care Facility Create Login And Verify",
    cbwtf_1: "Common Biomedical Waste Treatment Facility",
    user_1: "Users",
    uploadFile_1:'Waste File Upload',
    changePassword_1: "Change Password",
    dailyReport_1: "Daily Report : Detailed",
    dailyReportAllRoute_1 : "Daily Report : Daily Detailed (All Route)",
    monthlyReport_1: "Daily Report : Monthly Report",
    hcfnotvisited_1: "List of Health Care Facility Not Visited",
    hfcWiseReport_1: "Health Care Facility Wise Report",
    dailysummary_1: "Daily Report : Daily Summary",
    dailysummaryfactory_1: "Daily Summary : Plant",
    dailysummaryfactoryComp_1: "Waste Bags Scanned At Health Care Facility / Plant(Comparison)",
    hcfbedsdetails_1: "Health Care Facility-Beds Details",
    monthlyReportAllHcf_1: "Monthly Report : All Health Care Facility",
    monthlySummary_1: "Monthly Summary : All Health Care Facility",
    hcfCbwtfReport_1: "Health Care Facility - CBWTF Report",
    annualCbwtf_1: "Annual Report : Common Biomedical Waste Treatment Facility",
    hospitalRoute_1: "Health Care Facility Route Information",
    userConfiguration_1: "User Configuration",
    addRoute_1: "Route Master",
    hospitaldisplay_1: "Browse Health Care Facility List",
    edthcf_1: "Edit Health Care Facility Details",
    prntlbl_1: "Print Labels",
    labelInf_1 : "Print Barcode Information",
    route_1: 'Route',
    map_1: 'Locations',
    cbwtfreportdisplay_1: 'Statistics',
    emlsnt_1:'Acknowledgement For Waste Bags',
    ProperWstbg_1:'Succesfully Sent Waste Bags',
    ImProperWstbg_1: 'Waste Bags Not Sent to CPCB Server',
    PrintLbl_1:'Print Labels',
    PrintLblCnt_1:'Print Labels Count',
    unsentData_1: 'Unsend Health Care Facility',
    annlrpt_1: 'Annual Report',
    annlwstwt_1: 'Annual Waste Weight',
    annlwststrg_1: 'Annual Waste Storage',
    annleqp_1: 'Waste Equipment',
    annlmisc_1: 'Miscellaneous',
    hcfCountByState_1 : "Health Care Facility-Count By State",
    hcfCountBycbwtfid_1 : "Health Care Facility-Count By CBWTF-ID",
    hcfCountByRgd_1 : "Health Care Facility-Count By Regional Directory",
    spcb_frst_1: "Information : pollution Control Board",
    spcb_cbwtf_1: "Information : CBWTF",
    spcb_auth_1: "Information : Authorize",
    spcb_dstr_1: "Informatoin : District",
    spcb_hcfcptv_1: "Information : Health Care Facility",
    AddMnMsg_1: "Add Message",
    bagcountprhr_1: "Bags Count Per Hour",
    hcfNmbrVisited_1: "No of Health Care Facility Visited",
    hcfnotvstd_1: "Health Care Facility Not Visited",
    bigBag_1: "Large Waste Bags",
    bagcountlbl_1: "Bags With Labels",
    bagcntwthgeo_1: "Bags With Location",
    monthlyHcfStatus_1: "Health Care Facility Monthly Status",
    hcfupdated_1: "Health Care Facility Updated Recently",
    incompletePtlng_1: "Incomplete Health Care Facility",
    ctgCount_1: 'Health Care Facility Types And Counts',
    ctgCountChart_1: 'Health Care Facility Category Wise Chart',
    bhuvanmap_1: 'Bhuvan Map Information',
    bagsfrdt_1 : "Bags From Date",
    userRoute_1: 'Users : Route Details',
    hcfvisited_1: "Health Care Facility Attendance Marked",
    dailySummaryRoute_1: "Daily Report : Route Wise",
    testingWasteBags_1: "Waste Bags : Detailed Summary",
    vehicleSummary_1: "Daily Summary : Vehicle Wise",
    districtSummry_1: "Daily Summary : District Wise",
    hcfCompare_1: "Health Care Facility Comparative Analysis",
    CbwtfLst_1: ' List of Common Biomedical Waste Treatment Facility',
    hcfMstr_1: 'List of Health Care Facility',
    stthcfvisited_1: 'Health Care Facility Visited/Non-Visited',
    sttMissingRoute_1: 'Health Care Facility : Route Not Assigned',
    sttDashboard_1: 'Dashboard',
    sttDashboardCbwtf_1 :"Dashboard",
    liveLocation_1: "Vehicle's Live Location",
    // dailysummaryfactorynotscn_1 : "Waste Bags Not Scanned At Plant",
    trackmyfleet_1 : "Authorize Track My Fleet",
    ptlngerrcpcb_1 : "Unregistered At CPCB",
    ptlnmngrduplicateSpcb_1 : "Duplicate SPCB Codes",
    prevDashboard_1: "Previous Dashboard",
    hospitalDisplay_1: "Health Care Facility Display",
    usrroute_1: "Daily Summary : Scanned By User",
    supvsrroute_1 : "Daily Summary : Scanned At Plant",
    hcfVisited_1:"Health Care Facility Attendance Marked",
    summaryHcf_1 : "Health Care Facility Summary",
    printLblCnt_1: "Print Labels Count",
    qrcode_1: "Label Summary",
    vehicleHcfMap_1: "Live Location With Health Care Facilities",
    dupllblscn_1 : "Duplicate Label Scan",
    dailySummaryScnby_1: "Daily Summary : Scan By Health Care Facility / Operator / Plant",
    dailyReportScnby_1: "Daily Detailed : Scan By Health Care Facility / Operator / Plant",
    hcfnotvstmnth_1: "Monthly Report : Health Care Facility Not Visited",
    dailyReportwstbgcpcb_1 : 'Daily Report Of Waste Bags Sent To CPCB',
    dailyReportntwstbgcpcb_1 : 'Daily Report Of Waste Bags Not Sent To CPCB',
    mthlyreprtuntvst_1 : 'Monthly Report : Route Wise Not Visited',
    mthlyreprtu_1 : 'Monthly Report : Route Wise',
    hcfsrchbycty_1 : 'Health Care Facility In City',
    AckMails_1:'Acknowledgment Mails',
    duplicateSpcb_1:'Duplicate SPCB Codes',
    dailyRouteAll_1:'Daily Report : All Routes',
    validatefct_1:'Plant Scanning : User',
    smrmonthlyreport_1:' Monthly Summary Report',
    monthlyReportHcf_1:' Monthly Report : Health Care Facility Wise',
    annualReport_1:" Annual Report : Health Care Facility",
    wstbgdel_1:'Delete Waste Bags',
    PrintCertificate_1:'Registration Certificate',
    qrcodeWithDate_1:'Label Summary With Date',
    dailyreportfactory_1:'Daily Report : Plant Detailed'

  };