import { useEffect, useState } from "react";

type Tst = {
  data: any,
  className?: string
}

export const Toaster = (props: Tst) => {
  let cls: string = props.className ? props.className : "";
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const [className, setClassName] = useState<string>(
    `border rounded-lg flex justify-center items-center p-4 ${cls}`
  );

  useEffect(() => {
    if (props.data && props.data.message.length > 0) {
      setShowMessage(true);
    }
  }, [props.data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, [showMessage]);

  return (
    <>
      {showMessage && props.data &&
        props.data.message.length &&
        props.data.type === "error" && (
          <div
          className={`fixed top-12 right-4 border-red-600 ${className}`}
          style={{
            textAlign: "center",
            zIndex: 1001,
            backgroundColor: "rgba(255, 69, 58, 0.9)", // Brighter red background with higher opacity for contrast
            color: "#ffffff", // White text for clear visibility against the red background
          }}
        >
            <ul>
              {props.data.message.map((res: any, index: number) => (
                <li key={index}>
                  {/* {index + 1} : {res} */}
                  {res}
                </li>
              ))}
            </ul>
          </div>
        )}
      {showMessage && props.data &&
        (props.data.message.length || props.data.message.message) &&
        props.data.type === "success" && (
          <div
          className={`fixed top-12 right-4 border-green-600 ${className}`}
          style={{
            backgroundColor: "rgba(34, 139, 34, 0.9)", // Darker green background with higher opacity
            zIndex: 1001,
            textAlign: "center",
            color: "#ffffff", // White text to stand out against the green background
          }}
        >
            <ul>
              {props.data.message.map((res: any, index: number) => (
                <li key={index}>
                  {/* {index + 1} : {res} */}
                  {res}
                </li>
              ))}
            </ul>
          </div>
        )}
    </>
  );
};
