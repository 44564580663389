import React, { useEffect, useState } from "react";
import { pathNameHeading } from "../utilities/pathName";
import { useLocation } from "react-router-dom";
import Footer from "../components/reusable/Footer";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { createGetApi, dataStr_ToArray, GetResponseWnds } from "../utilities/utilities";
import { nrjAxios } from "../Hooks/useNrjAxios";

interface Hdr_1Props {
    children?: React.ReactNode;
}

export const Hdr_1: React.FC<Hdr_1Props> = ({ children }) => {
    const location = useLocation();
    const path = location.pathname.slice(1);
    const [headingName, setHeadingName] = useState<string>(pathNameHeading[path]);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [showcsvFleOpt, setSowCsvFleOpt] = useState(false)
    useEffect(() => {
        setHeadingName(pathNameHeading[path]);
    }, [location, path]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(prevDateTime => {
                const newDateTime = moment(prevDateTime).add(1, 'minute').toDate();
                return newDateTime;
            });
        }, 60000); // Update every minute

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array to only run once on mount

    const formatDateTime = (dateTime: Date) => {
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };
        const formattedTime = dateTime.toLocaleTimeString('en-GB', options).replace(/([ap]m)/i, match => match.toUpperCase());
        return `${formattedTime}`;
    };

    const getTime = () => {
        const api: string = createGetApi("db=nodb|dll=s|fnct=c183", "1");
        return nrjAxios({ apiCall: api });
    }

    const getTimeSuccess = (data: any) => {
        if (data) {
            data = GetResponseWnds(data);
            const ary: any = dataStr_ToArray(data);
            if (ary && ary[0]) {
                const dt: Date = moment({
                    year: Number(ary[0]['year']),
                    month: Number(ary[0]['month']) - 1,
                    day: Number(ary[0]['day']),
                    hour: Number(ary[0]['hour']),
                    minute: Number(ary[0]['min']),
                    second: Number(ary[0]['sec']),
                }).toDate();
                setCurrentDateTime(dt);
            }
        }
    }

    useQuery({
        queryKey: ["getserverdate"],
        queryFn: getTime,
        enabled: false,
        staleTime: Number.POSITIVE_INFINITY,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: getTimeSuccess,
    });

    // const getCsvFileUpldOptn = () => {
    //     let api: string = createGetApi("dll=hospdll|fnct=bx|db=nodb", "slc1245");
    //     return nrjAxios({ apiCall: api });
    //   }
    //   const getFileUpldSuccess = (data: any) => {
    
    //     let str: string = GetResponseWnds(data)
    //     if (str) {
    //       let ary : any = dataStr_ToArray(str)
    //       if (ary && parseInt(ary[0]['lng']) > -1) {
    //         sessionStorage.setItem('elf_whs_sgaBetsaW', ary[0]['lng']);
    //         setSowCsvFleOpt(true)
    //       } else {
    //         setSowCsvFleOpt(false)
    //         sessionStorage.removeItem('elf_whs_sgaBetsaW');
    //       }
    
    //     }
    
    //   }
      useEffect(() => {
        let flbag : string =sessionStorage.getItem('elf_whs_sgaBetsaW') || "0";
        if (flbag && parseInt(flbag) < 1) {
            setSowCsvFleOpt(false)
        //   refetchFileUpld()
        } else {
            setSowCsvFleOpt(true)
        }
        
      }, [])

    //   const { data: dataCsv, refetch: refetchFileUpld } = useQuery({
    //     queryKey: ["svFile"],
    //     queryFn: getCsvFileUpldOptn,
    //     enabled: false,
    //     staleTime: Number.POSITIVE_INFINITY,
    //     refetchOnWindowFocus: false,
    //     refetchOnReconnect: false,
    //     onSuccess: getFileUpldSuccess,
    //   });

    return (
        <>
            <div
                className="my-8"
                style={{
                    margin: '0 45px', // Only margin left and right
                    border: '2px solid #00000029', // Border
                    boxShadow: '0px 0px 20px 0px #00000029',
                    borderRadius: '8px', // Rounded corners
                }}
            >
                {((location.pathname !== '/') && (location.pathname !== '/forgotPassword_1')) && (
                    <div
                        className="p-7 rounded text-black flex justify-between items-center"
                        style={{
                            background: 'linear-gradient(90.29deg, #9CCD85 0%, #C7DD70 100%)',
                            borderRadius: '8px',
                        }}
                    >
                        <div className="text-2xl font-bold">
                            {headingName}
                            {location.pathname !== '/dshb_1' && (
                                <div className="text-sm font-normal mt-1">
                                    Please provide the requested information below
                                </div>
                            )}
                        </div>

                        <div className="text-xl font-semibold">
                            {formatDateTime(currentDateTime)}
                        </div>
                    </div>
                )}
                {children}
                <Footer />
            </div>
        </>
    );
};
