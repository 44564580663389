// export const Loader = () => (
//   <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-50 z-50">
//   <div className="relative flex justify-center items-center">
//     <div className="w-24 h-24 border-8 border-dashed border-gray-300 border-t-blue-500 animate-spin rounded-full opacity-70"></div>
//     <div className="absolute text-lg font-semibold text-gray-700">Loading...</div>
//   </div>
// </div>
//   );

import { useState, useEffect } from 'react';

export const Loader = () => {
  const [count, setCount] = useState(1);
  const [dots, setDots] = useState('');

  useEffect(() => {
    const countInterval = setInterval(() => {
      setCount((prevCount) => (prevCount < 100 ? prevCount + 1 : 100));
    }, 50);

    const dotsInterval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
    }, 500); // Adjust the speed of the dot animation

    return () => {
      clearInterval(countInterval);
      clearInterval(dotsInterval);
    };
  }, []);

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-50 z-50">
      <div className="relative flex flex-col items-center">
        {/* Container for Spinner and Percentage */}
        <div className="relative flex items-center justify-center w-24 h-24">
          {/* Spinning Circle */}
          <div className="absolute w-24 h-24 border-8 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
          {/* Fixed Percentage Text */}
          {/* <div className="relative text-2xl font-bold text-black">
            {count}%
          </div> */}
        </div>
        {/* Loading Text */}
        <div className="absolute top-28 text-lg font-semibold text-gray-700">
          Loading{dots}
        </div>
      </div>
    </div>
  );
};



